import LoadingOverlay from 'react-loading-overlay';
import styled from 'styled-components'
import React from "react"

const StyledLoader = styled(LoadingOverlay)`
    position:fixed !important;
    display: ${props => props.showLoader ? "initial" : "none"};
    z-index: ${props => props.zIndex};
    width:100vw;
    height:100vh;
    min-height:100vh;
    min-width:100vw;
    top:0;
    left:0;
`;

export default class Overlay extends React.Component{
    render() {
        return (<StyledLoader
            zIndex={this.props.zIndex ? this.props.zIndex : 10000}
            active={this.props.show_loading}
            spinner
            text={'Loading...'}
            classNamePrefix='MyLoader_'
            className={""}
            showLoader={this.props.show_loading}/>)
    }
}