import React from 'react';
import TableBase, {Column, SimpleTableComponentProps} from "./TableBase";
import SimpleHeader from "./SimpleHeader";
import SimpleBody from "./SimpleBody";

class SimpleTable extends React.Component{

    render(){
         return (
             <TableBase table_style={this.props.table_style ? this.props.table_style : null}>
                 <SimpleHeader evenWidthHeaders={this.props.evenWidthHeaders} columnClickedCallback={this.props.columnClickedCallback} columns={this.props.columns}/>
                 <SimpleBody appendRows={this.props.appendRows} rowBuilder={this.props.rowBuilder} columns={this.props.columns} table_data={this.props.table_data}/>
             </TableBase>
         );
    }
}
export default SimpleTable;