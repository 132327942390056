import React, { useState } from 'react'
import { sendPasswordResetEmail  } from "firebase/auth";
import { auth } from "../FirebaseConfig";
import { NavLink, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2';
import Overlay from "./Overlay";

// Initialize Firebase Authentication and get a reference to the service



function PasswordReset() {

  const navigate = useNavigate();
  const [email, setEmail] = useState();
  const [showLoading, setShowLoading] = useState(false);

  const sendEmail = (e, email, password) => {
    setShowLoading(true)
    e.preventDefault()
    sendPasswordResetEmail(auth, email)
    .then(() => {
      // Password reset email sent!
      // ..
      setShowLoading(false)
      Swal.fire({title: "Sent!", icon: "success", text:"Password reset email has been sent!", confirmButtonColor: '#002F87'})
    })
    .catch((error) => {
      setShowLoading(false)
      const errorCode = error.code;
      const errorMessage = error.message;
      Swal.fire({title: "Oops!", icon: "error", text:"Please try again!", confirmButtonColor: '#002F87'});
      // ..
    });
  }

  return (
    <>
    <Overlay show_loading={showLoading} />
      <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
        <form className='bg-light shadow-lg rounded d-flex flex-column align-items-center justify-content-between py-2 loginSection' style={{height: '40%'}} onSubmit={(e) => {sendEmail(e, email)}}>
          <section className='verlag-bold my-2' style={{ color: '#002F87', fontSize: '2rem', width: '90%'}}>
            <img
              src='/images/immy_logo.svg'
              width='50'
              height='50'
              className='d-inline-block align-top'
              alt=''
              style={{ color: '#002F87' }}
            />
            IMMY
            <hr className='mb-0'/>
          </section>
          <section className='w-75'>
            <div className="form-floating mb-3">
              <input type="text" className="form-control" id="floatingInput" placeholder="Sean" onChange={(e) => {setEmail(e.target.value)}}/>
              <label for="floatingInput">Email</label>
            </div>
          </section>
          <section className='my-2 w-50'>
            <button className='btn btn-primary w-100' type='submit'>Send Email</button>
          </section>
          <section className='my-1 w-75'>
            <p className='mb-1'>If you do not receive an email within 1 minute, please try again.</p>
            <a className="" href="/login">Return to Login</a>
          </section>
        </form>
      </div>
    </>
  )
}

export default PasswordReset

