import React from 'react'

function CustomerFooter(props) {
  return (
    <div className='col-12'>
      <hr className='m-1 my-3'/>
      <div className='footer col-12 mb-3 d-flex justify-content-start'>
        <button className="btn btn-primary mx-3" onClick={() => props.onClick()}>Submit</button>
      </div>
    </div>
  )
}

export default CustomerFooter