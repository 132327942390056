import React, {useState, useCallback, useMemo, useEffect, useRef} from 'react'


import { NavLink, useNavigate, redirect } from 'react-router-dom'
import axios from 'axios';
import SimpleTable from '../tables/SimpleTable'
import Overlay from "./Overlay";
import Swal from 'sweetalert2';
import ReCAPTCHA from "react-google-recaptcha"
import AdminHeader from './AdminHeader';
import { onAuthStateChanged, OAuthProvider, signInWithPopup, signInWithRedirect, getRedirectResult } from "firebase/auth";
import { auth } from "../FirebaseConfig";

function Admin() {

  const provider = new OAuthProvider('microsoft.com');
  provider.setCustomParameters({
    // Optional "tenant" parameter in case you are using an Azure AD tenant.
    // eg. '8eaef023-2b34-4da1-9baa-8bc8c9d6a490' or 'contoso.onmicrosoft.com'
    // or "common" for tenant-independent tokens.
    // The default value is "common".
    tenant: 'f1f1b8d0-6c95-49c7-bea5-ad1ad4acace6'
  });

  const navigate = useNavigate();
  const [recaptchaToken, setRecaptchaToken] = useState();
  const [uid, setUID] = useState();
  const [user, setUser] = useState();
  const [leads, setLeads] = useState();
  const [filteredLeads, setFilteredLeads] = useState();
  const [campaign, setCampaign] = useState(null);
  const [campaignOptions, setCampaignOptions] = useState();
  const [showLoading, setShowLoading] = useState(true);
  const [selectedLead, setSelectedLead] = useState();
  const [cragChecked, setCragChecked] = useState(false);
  const [aspChecked, setAspChecked] = useState(false);
  const [cocciLFAChecked, setCocciLFAChecked] = useState(false);
  const [IDCFChecked, setIDCFChecked] = useState(false);
  const [blastoChecked, setBlastoChecked] = useState(false);
  const [cocciEIAChecked, setCocciEIAChecked] = useState(false);
  const [histoChecked, setHistoChecked] = useState(false);
  const [mycoChecked, setMycoChecked] = useState(false);
  const [otherChecked, setOtherChecked] = useState(false);
  const [otherProducts, setOtherProducts] = useState("");
  const [productInterestMap, setProductInterestMap] = useState(new Map([
    ["CrAg", { checked: 0, Name: "CrAg LFA" }],
    ["Asp", { checked: 0, Name: "Aspergillus GM LFA" }],
    ["Cocci LFA", { checked: 0, Name: "Coccidioides Ab LFA" }],
    ["ID/CF", { checked: 0, Name: "ID/CF Reagents" }],
    ["Blasto", { checked: 0, Name: "Blastomyces Ab EIA" }],
    ["Cocci EIA", { checked: 0, Name: "Coccidioides Ab EIA" }],
    ["Histo", { checked: 0, Name: "Histoplasma GM EIA" }],
    ["Myco", { checked: 0, Name: "MycoDDR Reagents" }],
  ]));

  const reCaptchaRef = useRef();

  useEffect(() => {
    
    
    if (!leads) {
      axios
        .get("/salesforce/leads")
        .then((response) => {
          let records = response.data;
          setLeads(records);
          if (campaign) {
            records = records.filter((record) => {
              return record.Campaign__c === campaign;
            });
          } else {
            records = records.filter((record) => {
              return record.Campaign__c === null;
            });
          }
          setFilteredLeads(records);
        })
        .catch((e) => {
          console.error(e);
        });
      axios.get("/salesforce/campaigns").then((response) => {
        setCampaignOptions(response.data);
      });
      setShowLoading(false);
    } else {
      let records = leads;
      records = leads.filter((record) => {
        return record.Campaign__c === campaign;
      });
      setShowLoading(false);
      setFilteredLeads(records);
    }
  }, [campaign, leads]);

  useEffect(() => {
    let productInterest = selectedLead?.Product_Interest__c;
    if (productInterest?.includes("CrAg")) {
      setCragChecked(true);
      productInterestMap.set("CrAg", {
        ...productInterestMap.get("CrAg"),
        checked: 1,
      });
    }
    if (productInterest?.includes("Aspergillus")) {
      setAspChecked(true);
      productInterestMap.set("Asp", {
        ...productInterestMap.get("Asp"),
        checked: 1,
      });
    }
    if (productInterest?.includes("Coccidioides Ab LFA")) {
      setCocciLFAChecked(true);
      productInterestMap.set("Cocci LFA", {
        ...productInterestMap.get("Cocci LFA"),
        checked: 1,
      });
    }
    if (productInterest?.includes("ID/CF")) {
      setIDCFChecked(true);
      productInterestMap.set("ID/CF", {
        ...productInterestMap.get("ID/CF"),
        checked: 1,
      });
    }
    if (productInterest?.includes("Blastomyces")) {
      setBlastoChecked(true);
      productInterestMap.set("Blasto", {
        ...productInterestMap.get("Blasto"),
        checked: 1,
      });
    }
    if (productInterest?.includes("Coccidioides Ab EIA")) {
      setCocciEIAChecked(true);
      productInterestMap.set("Cocci EIA", {
        ...productInterestMap.get("Cocci EIA"),
        checked: 1,
      });
    }
    if (productInterest?.includes("Histoplasma")) {
      setHistoChecked(true);
      productInterestMap.set("Histo", {
        ...productInterestMap.get("Histo"),
        checked: 1,
      });
    }
    if (productInterest?.includes("MycoDDR")) {
      setMycoChecked(true);
      productInterestMap.set("Myco", {
        ...productInterestMap.get("Myco"),
        checked: 1,
      });
    }
    if(selectedLead?.Other_Product_Interest__c){
      setOtherChecked(true);
      setOtherProducts(selectedLead.Other_Product_Interest__c)
    }
  }, [selectedLead]);

  const productChange = (prod, checked) => {
    if (productInterestMap.get(prod)?.checked && prod !== "Other") {
      productInterestMap.set(prod, {
        ...productInterestMap.get(prod),
        checked: 0,
      });
      updateCheckBox(prod, false);
    } else if (productInterestMap.get(prod)?.checked && prod !== "Other") {
      productInterestMap.set(prod, {
        ...productInterestMap.get(prod),
        checked: 1,
      });
      updateCheckBox(prod, true);
    } else if(prod === "Other"){
      updateCheckBox(prod, !otherChecked)
    }
  };

  

  const updateCheckBox = (prod, bool) => {
    if (prod === "CrAg") {
      setCragChecked(bool);
    } else if (prod === "Asp") {
      setAspChecked(bool);
    } else if (prod === "Cocci LFA") {
      setCocciLFAChecked(bool);
    } else if (prod === "ID/CF") {
      setIDCFChecked(bool);
    } else if (prod === "Myco") {
      setMycoChecked(bool);
    } else if (prod === "Histo") {
      setHistoChecked(bool);
    } else if (prod === "Cocci EIA") {
      setCocciEIAChecked(bool);
    } else if (prod === "Blasto") {
      setBlastoChecked(bool);
    } else if(prod === "Other"){
      setOtherChecked(bool)
    }
  };

  const campaignChanged = (val) => {
    setSelectedLead({ ...selectedLead, Campaign__c: val });
  };

  const signIn = async () => {
    let userCred = await signInWithPopup(auth, provider)
    //console.log(userCred, "LOGIN")
    setUser(userCred.user.displayName)
    setUID(userCred.user.uid)
  }

  const onSubmit = async () => {
    setShowLoading(true);
    let checkedNames = [];
    const token = await reCaptchaRef.current.executeAsync();
    await setRecaptchaToken(token);
    reCaptchaRef.current.reset();
    //Creates list of Products in the format Salesforce needs.
    for (const [key, obj] of productInterestMap) {
      if (obj.checked === 1) {
        //console.log(obj);
        checkedNames.push(obj.Name);
      }
    }
    const productInterest = checkedNames.join(", ");
    let lead = selectedLead;
    lead.Product_Interest__c = productInterest;
    lead.Other_Product_Interest__c = otherChecked && otherProducts ? otherProducts : null;
    let data = {lead: lead, token: token}
    axios
      .post("/salesforce/updateLead", data)
      .then((response) => {
        setShowLoading(false);
        //console.log(response);
        if (response.data.success) {
          Swal.fire({
            title: "Success!",
            icon: "success",
            confirmButtonColor: "#002F87",
          }).then(() => {setShowLoading(false)})
          setSelectedLead();
          setLeads();
        } else {
          Swal.fire({
            title: "Oops!",
            icon: "error",
            confirmButtonColor: "#002F87",
          });
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };

  return (
    <>
      <Overlay show_loading={showLoading} />
      { uid ? <div className='bg-light shadow-lg d-flex flex-column justify-content-start align-content-center' style={{width: '85%', overflowY: "auto"}}>
        <AdminHeader 
        campaignChanged={(campaign) => {
          setShowLoading(true)
          setCampaign(campaign)
        }}
        user={user}
        />
        {user ? <h3 className='verlag-bold mt-3' style={{ color: '#002F87' }}>Welcome back, {user}!</h3> : <></>}
        <div className="card" style={{width: "90%", margin: "25px auto"}}>
          {/* <button onClick={() => signIn()}>LOGIN</button> */}
          <div className="card-header verlag-bold">
              <h4 className="text-center text-md-left">Leads</h4>
          </div>
          <div className="card-body p-0 m-0 table-responsive">
              {<SimpleTable table_style='tableFixHead' columns={[
                  {
                      label: "Name", key: "FirstName",
                      rawFormat: (val) => {
                          return <a href={"#"} className={'tableNameLinkColor'} data-bs-toggle="modal" data-bs-target="#leadModal" onClick={() => {
                            setSelectedLead(val)
                          }}>{val.FirstName + " " + val.LastName}</a>
                      },
                      popoverText: "Click to sort by Name"
                  },
                  {
                      label: "Company", key: "Company",
                      popoverText: "Click to sort by Enabled",
                  },
              ]} table_data={filteredLeads} columnClickedCallback={(col =>{
                  this.useSorter(col);
              })} />}
          </div>
        </div> 
      </div>
          : 
        <div className='d-flex flex-column align-content-center justify-content-center flex-grow-1'>
          <section className='card p-4' style={{margin: "0 auto"}}>
            <h3>Welcome!</h3>
            <section className='d-flex align-content-center justify-content-center mt-2'>
              <button className="btn btn-primary mx-3" onClick={() => signIn()}>Log In</button>
            </section>
          </section>
        </div>}
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_SITE_KEY}
          size="invisible"
          ref={reCaptchaRef}
          style={{bottom: "24px"}}
        />
        {/* Edit Lead Modal Start */}
        <div className="modal fade" id="leadModal" tabindex="-1" aria-labelledby="leadModal" data-bs-backdrop="static" aria-hidden="true">
                <div className="modal-dialog ">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h1 className="modal-title fs-5" id="leadModalLabel">Edit Lead Modal</h1>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setSelectedLead(null); setOtherProducts(null); setOtherChecked(false)}}></button>
                    </div>
                    <div className="modal-body editLeadModal">
                      <div className='row flex-column flex-md-row'>
                        <section className='px-4 col formSection z-0'>
                          <div className="form-floating mb-3">
                            <input type="text" className="form-control" id="floatingInput" placeholder="Sean" value={selectedLead?.FirstName} onChange={(e) => {setSelectedLead({...selectedLead, FirstName: e.target.value})}}/>
                            <label for="floatingInput">First Name</label>
                          </div>
                          <div className="form-floating mb-3">
                            <input type="text" className="form-control" id="floatingInput" placeholder="Sean" value={selectedLead?.LastName} onChange={(e) => {setSelectedLead({...selectedLead, LastName: e.target.value})}}/>
                            <label for="floatingInput">Last Name</label>
                          </div>
                          <div className="form-floating mb-3">
                            <input type="text" className="form-control" id="floatingInput" placeholder="Sean" value={selectedLead?.Title} onChange={(e) => {setSelectedLead({...selectedLead, Title: e.target.value})}}/>
                            <label for="floatingInput">Title</label>
                          </div>
                          {campaignOptions && selectedLead ? <>
                            <div className="form-floating mb-3">
                              <select className="form-select" onChange={(e) => {campaignChanged(e.target.value)}} id="floatingSelect" aria-label="Floating label select example">
                                <option value={null}>Unassigned</option>
                                {campaignOptions.map((campaign) => {
                                  if(campaign.columns[0].value === selectedLead?.['Campaign__r.Name']){
                                    return <option selected value={campaign.columns[6].value}>{campaign.columns[0].value}</option>
                                  } else {
                                    return <option value={campaign.columns[6].value}>{campaign.columns[0].value}</option>
                                  }
                                })}
                              </select>
                              <label for="floatingSelect">Campaign</label>
                            </div>
                            </> : 
                            <>
                              <div className="form-floating mb-3">
                                <select className="form-select headerSelect verlag-bold campaignDropdown" onChange={(e) => {campaignChanged(e.target.value)}} id="floatingSelect" aria-label="Floating label select example">
                                  <option selected>Loading...</option>
                                </select>
                                <label for="floatingSelect">Campaign</label>
                              </div>
                            </>}
                        </section>
                        <section className='px-4 col formSection z-0'>
                          <div className="form-floating mb-3">
                            <input type="text" className="form-control" id="floatingInput" placeholder="Sean" value={selectedLead?.Company} onChange={(e) => {setSelectedLead({...selectedLead, Company: e.target.value})}}/>
                            <label for="floatingInput">Facility/Organization</label>
                          </div>
                          <div className="form-floating mb-3">
                            <input type="text" className="form-control" id="floatingInput" placeholder="Sean" value={selectedLead?.Email} onChange={(e) => {setSelectedLead({...selectedLead, Email: e.target.value})}}/>
                            <label for="floatingInput">Email</label>
                          </div>
                          <div className="form-floating mb-3">
                            <input type="text" className="form-control" id="floatingInput" placeholder="Sean" value={selectedLead?.Phone} onChange={(e) => {setSelectedLead({...selectedLead, Phone: e.target.value})}}/>
                            <label for="floatingInput">Phone</label>
                          </div>
                        </section>
                      </div>
                    <div className='d-flex flex-column container-fluid'>
                    <hr className='m-1'/>
                    <h3 className='py-3 m-0'>Product Interests</h3>
                    <div className='row px-3'>
                      <section className='px-1 py-2 col-md-4 col-lg-4 z-0'>
                        <div className="input-group h-100" onClick={(e) => {productChange('CrAg', e.target.checked)}}>
                          <div className="input-group-text col-12 ProductTab">
                            <input className="form-check-input mt-0" type="checkbox" checked={cragChecked} aria-label="Checkbox for following text input" is="inlineRadio1"/>
                            <div className="vr mx-2"></div>
                            <label className="form-check-label text-wrap flex-grow-1" for="inlineRadio1" style={{fontSize: "12px"}}>CrAg LFA</label>
                          </div>
                        </div>
                      </section>
                      <section className='px-1 py-2 col-md-4 col-lg-4 z-0'>
                        <div className="input-group h-100" onClick={(e) => {productChange('Asp', e.target.checked)}}>
                          <div className="input-group-text col-12 ProductTab">
                            <input className="form-check-input mt-0" type="checkbox" checked={aspChecked} aria-label="Checkbox for following text input" is="inlineRadio1" />
                            <div className="vr mx-2"></div>
                            <label className="form-check-label text-wrap flex-grow-1" for="inlineRadio1" style={{fontSize: "12px"}}>Asp. GM LFA</label>
                          </div>
                        </div>
                      </section>
                      <section className='px-1 py-2 col-md-4 col-lg-4 z-0'>
                        <div className="input-group h-100" onClick={(e) => {productChange('Cocci LFA', e.target.checked)}}>
                          <div className="input-group-text col-12 ProductTab">
                            <input className="form-check-input mt-0" type="checkbox" checked={cocciLFAChecked} aria-label="Checkbox for following text input" is="inlineRadio1"/>
                            <div className="vr mx-2"></div>
                            <label className="form-check-label text-wrap flex-grow-1" for="inlineRadio1" style={{fontSize: "12px"}}>Cocci Ab LFA</label>
                          </div>
                        </div>
                      </section>
                      <section className='px-1 py-2 col-md-4 col-lg-4 z-0'>
                        <div className="input-group h-100" onClick={(e) => {productChange('ID/CF', e.target.checked)}}>
                          <div className="input-group-text col-12 ProductTab">
                            <input className="form-check-input mt-0" type="checkbox" checked={IDCFChecked} aria-label="Checkbox for following text input" is="inlineRadio1" />
                            <div className="vr mx-2"></div>
                            <label className="form-check-label text-wrap flex-grow-1" for="inlineRadio1" style={{fontSize: "12px"}}>ID/CF</label>
                          </div>
                        </div>
                      </section>
                      <section className='px-1 py-2 col-md-4 col-lg-4 z-0'>
                        <div className="input-group h-100" onClick={(e) => {productChange('Myco', e.target.checked)}}>
                          <div className="input-group-text col-12 ProductTab">
                            <input className="form-check-input mt-0" type="checkbox" checked={mycoChecked} aria-label="Checkbox for following text input" is="inlineRadio1" />
                            <div className="vr mx-2"></div>
                            <label className="form-check-label text-wrap flex-grow-1" for="inlineRadio1" style={{fontSize: "12px"}}>MycoDDR Trident</label>
                          </div>
                        </div>
                      </section>
                      <section className='px-1 py-2 col-md-4 col-lg-4 z-0'>
                        <div className="input-group h-100" onClick={(e) => {productChange('Histo', e.target.checked)}}>
                          <div className="input-group-text col-12 ProductTab">
                            <input className="form-check-input mt-0" type="checkbox" checked={histoChecked} aria-label="Checkbox for following text input" is="inlineRadio1" />
                            <div className="vr mx-2"></div>
                            <label className="form-check-label text-wrap flex-grow-1" for="inlineRadio1" style={{fontSize: "12px"}}>Histo GM EIA</label>
                          </div>
                        </div>
                      </section>
                      <section className='px-1 py-2 col-md-4 col-lg-4 z-0'>
                        <div className="input-group h-100" onClick={(e) => {productChange('Cocci EIA', e.target.checked)}}>
                          <div className="input-group-text col-12 ProductTab">
                            <input className="form-check-input mt-0" type="checkbox" checked={cocciEIAChecked} aria-label="Checkbox for following text input" is="inlineRadio1" />
                            <div className="vr mx-2"></div>
                            <label className="form-check-label text-wrap flex-grow-1" for="inlineRadio1" style={{fontSize: "12px"}}>Cocci Ab EIA</label>
                          </div>
                        </div>
                      </section>
                      <section className='px-1 py-2 col-md-4 col-lg-4 z-0'>
                        <div className="input-group h-100" onClick={(e) => {productChange('Blasto', e.target.checked)}}>
                          <div className="input-group-text col-12 ProductTab">
                            <input className="form-check-input mt-0" type="checkbox" checked={blastoChecked} aria-label="Checkbox for following text input" is="inlineRadio1" />
                            <div className="vr mx-2"></div>
                            <label className="form-check-label text-wrap flex-grow-1" for="inlineRadio1" style={{fontSize: "12px"}}>Blasto Ab EIA</label>
                          </div>
                        </div>
                      </section>
                      <section className='px-1 py-2 col-md-12 col-lg-12 z-0'>
                        <div className="input-group h-100" onClick={(e) => {
                          if(!otherChecked){
                            productChange('Other', e.target.checked)}
                          }}
                        >
                          <div className="input-group-text col-12 ProductTab">
                            <input className="form-check-input mt-0" type="checkbox" checked={otherChecked} onClick={(e) => {
                              if(otherChecked){
                                productChange('Other', e.target.checked)}
                              }}
                              aria-label="Checkbox for following text input" 
                              is="inlineRadio1" 
                            />
                            <div className="vr mx-2"></div>
                            
                            {otherChecked ? 
                              <textarea className="form-control" placeholder="Other..." id="floatingTextarea" value={otherProducts} rows={1} onChange={(e) => {setOtherProducts(e.target.value)}} style={{resize: "none"}}></textarea>
                            : <><label className="form-check-label text-wrap flex-grow-1" for="inlineRadio1" style={{fontSize: "12px"}}>Other</label></>}
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                    <div className="form-floating">
                      <textarea className="form-control mt-3" placeholder="Add additional notes" id="floatingTextarea" value={selectedLead?.Notes__c} onChange={(e) => {setSelectedLead({...selectedLead, Notes__c: e.target.value})}}></textarea>
                      <label for="floatingTextarea">Notes</label>
                    </div>
                    </div>
                    <div className={selectedLead?.Badge_Business_Card_Image__c ? "modal-footer d-flex justify-content-between" : "modal-footer d-flex justify-content-end" }>
                      {selectedLead?.Badge_Business_Card_Image__c ? <a type="button" className="btn btn-success" target='_blank' rel='noreferrer' href={selectedLead?.Badge_Business_Card_Image__c }>View Image</a> : <></>}
                      <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => {onSubmit()}}>Save changes</button>
                    </div>
                  </div>
                </div>
              </div>
              {/* Edit Lead Modal End */}
    </>
  )
}

export default Admin