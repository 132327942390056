import React, { useState } from 'react'
import { signInWithEmailAndPassword  } from "firebase/auth";
import { auth } from "../FirebaseConfig";
import { NavLink, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2';
import Overlay from "./Overlay";

// Initialize Firebase Authentication and get a reference to the service



function Signin() {

  const navigate = useNavigate();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [showLoading, setShowLoading] = useState(false);

  const logIn = (e, email, password) => {
    setShowLoading(true)
    e.preventDefault()
    signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed in 
      const user = userCredential.user;
      navigate("/admin")
    })
    .catch((error) => {
      setShowLoading(false)
      const errorCode = error.code;
      const errorMessage = error.message;
      Swal.fire({title: "Oops!", icon: "error", text:"Incorrect Email or Password", confirmButtonColor: '#002F87'});
      console.log(errorCode, errorMessage)
    });
  }

  return (
    <>
    <Overlay show_loading={showLoading} />
      <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
        <form className='bg-light shadow-lg rounded d-flex flex-column align-items-center justify-content-between loginSection' style={{height: '40%'}} onSubmit={(e) => {logIn(e, email, password)}}>
          <section className='verlag-bold my-2' style={{ color: '#002F87', fontSize: '2rem', width: '90%'}}>
            <img
              src='/images/immy_logo.svg'
              width='50'
              height='50'
              className='d-inline-block align-top'
              alt=''
              style={{ color: '#002F87' }}
            />
            IMMY
            <hr className='mb-0'/>
          </section>
          <section className='w-75'>
            <div className="form-floating mb-3">
              <input type="text" className="form-control" id="floatingInput" placeholder="Sean" onChange={(e) => {setEmail(e.target.value)}}/>
              <label for="floatingInput">Email</label>
            </div>
            <div className="form-floating mb-3">
              <input type="password" className="form-control" id="floatingInput" placeholder="Sean" onChange={(e) => {setPassword(e.target.value)}}/>
              <label for="floatingInput">Password</label>
            </div>
            <a className="w-100 text-left" href="/reset">Reset Password</a>
          </section>
          <section className='my-3 w-50'>
            <button className='btn btn-primary w-100' type='submit'>Login</button>
          </section>
        </form>
      </div>
    </>
  )
}

export default Signin

