import React from "react";
import {Column} from "./TableBase";

export function getValue(values, key) {
    if(!key){return ""}
    if(key.includes(". "))
        return values[key];
    let keys = key.split('.');
    let val = values;
    keys.forEach(function (k, index) {
        val = val ? val[keys[index]] : ""
    });
    return val;
}
export function getModalLink(display_string, modal_data, props) {
    return (<a href="#!" onClick={() => props.load_modal(modal_data)}>{display_string}</a>)
}

export function buildDisplayString(col, val, props){
    let disString = col.raw ? col.raw : getValue(val, col.key)
    disString = col.rawFormat ? col.rawFormat(val) : disString;
    disString = col.formatFunc ? col.formatFunc(disString) : disString;
    disString = (col.modal_key || col.modal_data) ? (getModalLink(disString, col.isOwnModalData ? val : val[col.modal_key], props)) : disString;
    disString = col.onClick ? <a href={"#!"} onClick={() => col.onClick(val)}>{disString}</a> : disString
    return disString
}