import React from "react";


export default class TableBase extends React.Component{
    constructor(props){
        super(props)
    }

    render(){
        return (
            <table className={"table " + (this.props.table_style ? this.props.table_style : " p-0 m-0")}>
                {this.props.children}
            </table>
        )
    }
}