import {initializeApp} from "firebase/app"
import {getAuth, initializeAuth, setPersistence, browserSessionPersistence, signInWithEmailAndPassword} from "firebase/auth"

const firebaseConfig = {
  apiKey: "AIzaSyCteoKDFEZRNMDPSwRs1gT7jalBME63AAQ",
  authDomain: "immy-applications.firebaseapp.com",
  projectId: "immy-applications",
  storageBucket: "immy-applications.appspot.com",
  messagingSenderId: "462019074283",
  appId: "1:462019074283:web:3d698f3335ac00b2e1c6ae"
};

const app = initializeApp(firebaseConfig)
const auth = getAuth(app);
setPersistence(auth, browserSessionPersistence)
  .then(() => {
    // Existing and future Auth states are now persisted in the current
    // session only. Closing the window would clear any existing state even
    // if a user forgets to sign out.
    // ...
    // New sign-in will be persisted with session persistence.
  })
  .catch((error) => {
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
  });
export {app, auth}