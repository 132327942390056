import React from "react";

import {buildDisplayString} from "./TableUtil";
import {Column, SimpleTableComponentProps} from "./TableBase";

export default class SimpleBody extends React.Component{

    defaultRow(col, val){
        if(col.override) return col.override;
        return (<td className="TableDataWrap">{buildDisplayString(col, val)}</td>)
    }

    getRows(){
        return (this.props.table_data ? this.props.table_data.map((val)  => {
            return (<tr>{
                    this.props.columns.map((col) => {
                        return this.defaultRow(col, val);
                    })
                }</tr>
            )
        }) : null);
    }

    render() {
        return(<tbody>
            {this.props.prependRows ? this.props.prependRows : null}
            {this.props.rowBuilder ? this.props.rowBuilder(this.props.table_data, this.props.columns) : this.getRows()}
            {this.props.appendRows ? this.props.appendRows : null}
            </tbody>
          )
    }
}