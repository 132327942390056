import React, {useState, useCallback, useMemo, useEffect, useRef} from 'react'
import CustomerHeader from './CustomerHeader'
import ImageCapture from 'react-image-data-capture';
import CustomerFooter from './CustomerFooter';
import axios from 'axios';
import AutoComplete from './Autocomplete';
import ReCAPTCHA from "react-google-recaptcha"
import Swal from 'sweetalert2';
import Overlay from "./Overlay";
import Webcam from "react-webcam";

import {auth} from "../FirebaseConfig";

function Home() {

    const [submitted, setSubmitted] = useState(0);
    const [log, setLog] = useState();
    const [mediaActive, setMediaActive] = useState(false);
    const [imgSrc, setImgSrc] = useState(null);
    const [imgFile, setImgFile] = useState(null);
    const [recaptchaToken, setRecaptchaToken] = useState();
    const [showLoading, setShowLoading] = useState(false);
    const [cragChecked, setCragChecked] = useState(false);
    const [aspChecked, setAspChecked] = useState(false);
    const [cocciLFAChecked, setCocciLFAChecked] = useState(false);
    const [IDCFChecked, setIDCFChecked] = useState(false);
    const [blastoChecked, setBlastoChecked] = useState(false);
    const [cocciEIAChecked, setCocciEIAChecked] = useState(false);
    const [histoChecked, setHistoChecked] = useState(false);
    const [mycoChecked, setMycoChecked] = useState(false);
    const [otherChecked, setOtherChecked] = useState(false);
    const [otherProducts, setOtherProducts] = useState("");
    const [selectedImage, setSelectedImage] = useState(null);
    const [formData, setFormData] = useState({
        FirstName: "",
        LastName: "",
        Title: "",
        Company: "",
        Email: "",
        Phone: "",
        ProductInterest: new Map([
            ["CrAg", {checked: 0, Name: "CrAg LFA"}],
            ["Asp", {checked: 0, Name: "Aspergillus GM LFA"}],
            ["Cocci LFA", {checked: 0, Name: "Coccidioides Ab LFA"}],
            ["ID/CF", {checked: 0, Name: "ID/CF Reagents"}],
            ["Blasto", {checked: 0, Name: "Blastomyces Ab EIA"}],
            ["Cocci EIA", {checked: 0, Name: "Coccidioides Ab EIA"}],
            ["Histo", {checked: 0, Name: "Histoplasma GM EIA"}],
            ["Myco", {checked: 0, Name: "MycoDDR Reagents"}],
        ]),
        Product_Interest__c: "",
        Notes__c: "",
        Campaign__c: ""
    });
    const [location, setLocation] = useState({
        street: "",
        city: "",
        state: "",
        postalCode: "",
        country: "",
    });

    const webcamRef = React.useRef(null);
    const reCaptchaRef = useRef();

    useEffect(() => {
        if (submitted === 1) {
            postSalesForceData();
            setSubmitted(0);
        }
        navigator.mediaDevices.getUserMedia({video: {facingMode: "environment"}}).then((res) => {
            console.log(res)
            setLog(res.active)
        })
        navigator.mediaDevices.getUserMedia({
            video: {
                facingMode: "environment"
            }
        })
            .then((res) => {
                setMediaActive(res.active)
            })
        //console.log(navigator.mediaDevices.getUserMedia({ video: { width: 300 } }))
    }, [formData]);

    useEffect(() => {
        if (submitted === 1) onSubmit();
    }, [submitted]);

    const onCapture = (imageData) => {
        //console.log("CAPTURE");
        // read as webP
        setImgSrc(imageData.webP);
        // read as file
        //(imageData);
        setImgFile(new File([imageData.blob], "lead.png", {type: "image/png"}));
        // read as blob
        // imageData.blob
    };

    // Use useCallback to avoid unexpected behaviour while rerendering
    const onError = useCallback((error) => {
        console.log(error);
    }, []);

    // Use useMemo to avoid unexpected behaviour while rerendering
    const config = useMemo(() => ({
        video: {
            facingMode: "environment"
        }
    }), []);

    const productChange = (prod, checked) => {
        if (formData.ProductInterest.get(prod)?.checked && prod !== "Other") {
            formData.ProductInterest.set(prod, {
                ...formData.ProductInterest.get(prod),
                checked: 0,
            });
            updateCheckBox(prod, false)
        } else if (!formData.ProductInterest.get(prod)?.checked && prod !== "Other") {
            formData.ProductInterest.set(prod, {
                ...formData.ProductInterest.get(prod),
                checked: 1,
            });
            updateCheckBox(prod, true)
        } else if (prod === "Other") {
            updateCheckBox(prod, !otherChecked)
        }
    };

    const updateCheckBox = (prod, bool) => {
        if (prod === 'CrAg') {
            setCragChecked(bool)
        } else if (prod === 'Asp') {
            setAspChecked(bool)
        } else if (prod === 'Cocci LFA') {
            setCocciLFAChecked(bool)
        } else if (prod === 'ID/CF') {
            setIDCFChecked(bool)
        } else if (prod === 'Myco') {
            setMycoChecked(bool)
        } else if (prod === 'Histo') {
            setHistoChecked(bool)
        } else if (prod === 'Cocci EIA') {
            setCocciEIAChecked(bool)
        } else if (prod === 'Blasto') {
            setBlastoChecked(bool)
        } else if (prod === "Other") {
            setOtherChecked(bool)
        }
    }
    //clears all state variables to prep for next entry
    const clearState = () => {
        setFormData({
            FirstName: "",
            LastName: "",
            Title: "",
            Company: "",
            Email: "",
            Phone: "",
            ProductInterest: new Map([
                ["CrAg", {checked: 0, Name: "CrAg LFA"}],
                ["Asp", {checked: 0, Name: "Aspergillus GM LFA"}],
                ["Cocci LFA", {checked: 0, Name: "Coccidioides Ab LFA"}],
                ["ID/CF", {checked: 0, Name: "ID/CF Reagents"}],
                ["Blasto", {checked: 0, Name: "Blastomyces Ab EIA"}],
                ["Cocci EIA", {checked: 0, Name: "Coccidioides Ab EIA"}],
                ["Histo", {checked: 0, Name: "Histoplasma GM EIA"}],
                ["Myco", {checked: 0, Name: "MycoDDR Reagents"}],
            ]),
            Product_Interest__c: "",
            Notes__c: "",
        });
        setImgFile(null);
        setImgSrc(null);
        setLocation({
            street: "",
            city: "",
            state: "",
            postalCode: "",
            country: "",
        });
        setSubmitted(0);
        setCragChecked(false);
        setAspChecked(false);
        setCocciLFAChecked(false);
        setIDCFChecked(false);
        setBlastoChecked(false);
        setCocciEIAChecked(false);
        setHistoChecked(false);
        setMycoChecked(false);
        //clears address autocomplete field
        document.getElementById('autocomplete').value = '';
    };

    const capture = React.useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        setImgSrc(imageSrc);
        fetch(imageSrc)
            .then(res => res.blob())
            .then((elem) => setImgFile(elem))
    }, [webcamRef, setImgSrc]);

    const postSalesForceData = () => {
        let data = new FormData();
        let unixTimeStamp = Date.now()
        data.set("lead", JSON.stringify(formData));
        data.set("token", JSON.stringify(recaptchaToken));
        data.set("timeStamp", JSON.stringify(unixTimeStamp));
        if (imgFile)
            data.append("image", imgFile, `${formData.Company}-${formData.FirstName}-${formData.LastName}-${unixTimeStamp}.png`);
        console.log(imgFile)
        // for (const [key, value] of data.entries()) {
        //   console.log(key, value);
        // }
        axios
            .post("/salesforce/lead", data)
            .then((response) => {
                setShowLoading(false)
                //console.log(response);
                if (response.data.success) {
                    Swal.fire({title: "Success!", icon: "success", confirmButtonColor: '#002F87'});
                    clearState();
                } else {
                    Swal.fire({title: "Oops!", icon: "error", confirmButtonColor: '#002F87'});
                }
            })
            .catch((e) => {
                console.error(e);
            });
    };

    const validateFields = async () => {
        if (!formData.FirstName) {
            Swal.fire({
                title: "Incomplete Form!",
                text: "Please complete the First Name field.",
                icon: "error",
                confirmButtonColor: '#002F87'
            })
                .then(() => {
                    setSubmitted(0);
                    return false;
                })
        } else if (!formData.LastName) {
            Swal.fire({
                title: "Incomplete Form!",
                text: "Please complete the Last Name field.",
                icon: "error",
                confirmButtonColor: '#002F87'
            })
                .then(() => {
                    setSubmitted(0);
                    return false;
                })
        } else if (!formData.Company) {
            Swal.fire({
                title: "Incomplete Form!",
                text: "Please complete the Company field.",
                icon: "error",
                confirmButtonColor: '#002F87'
            })
                .then(() => {
                    setSubmitted(0);
                    return false;
                })
        } else if (!formData.Campaign__c || formData.Campaign__c === "Unassigned" || !formData.hasOwnProperty("Campaign__c")) {
            Swal.fire({
                title: "Incomplete Form!",
                text: "Please select a campaign before submitting.",
                icon: "error",
                confirmButtonColor: '#002F87'
            })
                .then(() => {
                    setSubmitted(0);
                    return false;
                })
        } else {
            return true;
        }
    }

    const onSubmit = async () => {
        let isFormComplete = await validateFields();
        if (isFormComplete) {
            setShowLoading(true);
            const token = await reCaptchaRef.current.executeAsync();
            setRecaptchaToken(token);
            reCaptchaRef.current.reset();
            //console.log("submit");
            let checkedNames = [];

            //Creates list of Products in the format Salesforce needs.
            for (const [key, obj] of formData.ProductInterest) {
                if (obj.checked === 1) {
                    //console.log(obj);
                    checkedNames.push(obj.Name);
                }
            }
            let productInterest = checkedNames.join(", ");

            setFormData({
                ...formData,
                ...location,
                Product_Interest__c: productInterest,
                Other_Product_Interest__c: otherProducts
            });
        }

    };

    return (
        <div className='bg-light shadow-lg d-flex flex-column justify-content-between pb-4 pb-sm-0'
             style={{width: '85%', overflowY: "auto"}}>

            <div className='container-fluid d-flex flex-column justify-content-between'>
                <Overlay show_loading={showLoading}/>
                <CustomerHeader campaignChanged={(campaign) => {
                    const currentDate = new Date();

                    // Add 24 hours (24 * 60 * 60 * 1000 milliseconds) to the current date
                    const futureDate = new Date(currentDate.getTime() + 12 * 60 * 60 * 1000);

                    // Format the futureDate as a string in UTC
                    const futureDateUTCString = futureDate.toString();

                    setFormData({...formData, Campaign__c: campaign});
                    document.cookie = `campaign=${campaign}; expires=${futureDateUTCString}`;
                }}/>
                <div className='row flex-column flex-md-row'>
                    <section className='px-4 col formSection z-0'>
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control" id="floatingInput" placeholder="Sean"
                                   value={formData.FirstName} onChange={(e) => {
                                setFormData({...formData, FirstName: e.target.value})
                            }}/>
                            <label for="floatingInput">First Name</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control" id="floatingInput" placeholder="Bauman"
                                   value={formData.LastName} onChange={(e) => {
                                setFormData({...formData, LastName: e.target.value})
                            }}/>
                            <label for="floatingInput">Last Name</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control" id="floatingInput" placeholder="CEO"
                                   value={formData.Title} onChange={(e) => {
                                setFormData({...formData, Title: e.target.value})
                            }}/>
                            <label for="floatingInput">Title</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control" id="floatingInput"
                                   placeholder="Immuno Mycologics" value={formData.Company} onChange={(e) => {
                                setFormData({...formData, Company: e.target.value})
                            }}/>
                            <label for="floatingInput">Facility/Organization</label>
                        </div>
                    </section>
                    <section className='px-4 col formSection z-0'>
                        <AutoComplete updateFormData={(addr, city, state, zip, country) => {
                            setLocation({street: addr, city: city, state: state, postalCode: zip, country: country})
                        }}/>
                        <div className="form-floating mb-3">
                            <input type="email" className="form-control" id="floatingInput" value={formData.Email}
                                   placeholder="Marketing@immy.com" onChange={(e) => {
                                setFormData({...formData, Email: e.target.value})
                            }}/>
                            <label for="floatingInput">Email</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input type="Phone" className="form-control" id="floatingInput" value={formData.Phone}
                                   placeholder="(405) 360-4669" onChange={(e) => {
                                setFormData({...formData, Phone: e.target.value})
                            }}/>
                            <label for="floatingInput">Phone</label>
                        </div>
                        <div className='d-flex justify-content-center align-items-center mb-2'
                             style={{height: '58px', color: "rgb(255, 255, 255)", fontWeight: 400}}>
                            <a type="button"
                               className="btn btn-primary h-100 d-flex justify-content-center align-items-center captureButton"
                               data-bs-toggle="modal" data-bs-target="#imageCapture" st>Badge/ Business Card Capture</a>
                        </div>
                    </section>
                </div>
                {/* Image Capture Modal Start */}
                <div className="modal" id="imageCapture" tabindex="-1" aria-labelledby="imageCaptureLabel"
                     aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="exampleModalLabel">Badge/ Business Card
                                    Capture</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className='d-flex justify-content-center align-items-center flex-column'>
                                    <Webcam
                                        audio={false}
                                        className='w-75'
                                        ref={webcamRef}
                                        screenshotFormat='image/png'
                                        videoConstraints={{
                                            facingMode: "environment"
                                        }}
                                    />
                                    {imgSrc &&
                                        <div className='mx-3'>
                                            <img src={imgSrc} alt="captured-img"/>
                                        </div>
                                    }
                                    {
                                        selectedImage && (
                                            <div>
                                                <img alt="not found"
                                                     width={"250px"}
                                                     src={URL.createObjectURL(selectedImage)}/>
                                                <br/>
                                                <button className="btn btn-danger m-3"
                                                        onClick={() => setSelectedImage(null)}>Remove
                                                </button>
                                            </div>
                                        )}

                                    <br/>
                                    <br/>
                                    <div style ={{display: "inline-block"}}>
                                        <input
                                            type="file"
                                            name="myImage"
                                            onChange={(event) => {
                                                console.log(event.target.files[0]);
                                                setSelectedImage(event.target.files[0]);
                                                setImgFile(event.target.files[0]);
                                            }}
                                        />
                                        <button className="btn btn-primary m-3" onClick={capture}>Capture photo</button>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"
                                        onClick={() => {
                                            setImgFile(null);
                                            setImgSrc(null)
                                        }}>Close
                                </button>
                                <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Save changes
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Image Capture Modal End */}
            <div className='d-flex flex-column container-fluid'>
                <hr className='m-1'/>
                <h3 className='py-3 m-0'>Product Interests</h3>
                <div className='row px-3'>
                    <section className='px-1 py-2 col-md-3 col-lg-2 z-0'>
                        <div className="input-group h-100" onClick={(e) => {
                            productChange('CrAg', e.target.checked)
                        }}>
                            <div className="input-group-text col-12 ProductTab">
                                <input className="form-check-input mt-0" type="checkbox" checked={cragChecked}
                                       aria-label="Checkbox for following text input" is="inlineRadio1"/>
                                <div className="vr mx-2"></div>
                                <label className="form-check-label text-wrap flex-grow-1" for="inlineRadio1"
                                       style={{fontSize: "12px"}}>CrAg LFA</label>
                            </div>
                        </div>
                    </section>
                    <section className='px-1 py-2 col-md-3 col-lg-2 z-0'>
                        <div className="input-group h-100" onClick={(e) => {
                            productChange('Asp', e.target.checked)
                        }}>
                            <div className="input-group-text col-12 ProductTab">
                                <input className="form-check-input mt-0" type="checkbox" checked={aspChecked}
                                       aria-label="Checkbox for following text input" is="inlineRadio1"/>
                                <div className="vr mx-2"></div>
                                <label className="form-check-label text-wrap flex-grow-1" for="inlineRadio1"
                                       style={{fontSize: "12px"}}>Asp. GM LFA</label>
                            </div>
                        </div>
                    </section>
                    <section className='px-1 py-2 col-md-3 col-lg-2 z-0'>
                        <div className="input-group h-100" onClick={(e) => {
                            productChange('Cocci LFA', e.target.checked)
                        }}>
                            <div className="input-group-text col-12 ProductTab">
                                <input className="form-check-input mt-0" type="checkbox" checked={cocciLFAChecked}
                                       aria-label="Checkbox for following text input" is="inlineRadio1"/>
                                <div className="vr mx-2"></div>
                                <label className="form-check-label text-wrap flex-grow-1" for="inlineRadio1"
                                       style={{fontSize: "12px"}}>Cocci Ab LFA</label>
                            </div>
                        </div>
                    </section>
                    <section className='px-1 py-2 col-md-3 col-lg-2 z-0'>
                        <div className="input-group h-100" onClick={(e) => {
                            productChange('ID/CF', e.target.checked)
                        }}>
                            <div className="input-group-text col-12 ProductTab">
                                <input className="form-check-input mt-0" type="checkbox" checked={IDCFChecked}
                                       aria-label="Checkbox for following text input" is="inlineRadio1"/>
                                <div className="vr mx-2"></div>
                                <label className="form-check-label text-wrap flex-grow-1" for="inlineRadio1"
                                       style={{fontSize: "12px"}}>ID/CF</label>
                            </div>
                        </div>
                    </section>
                    <section className='px-1 py-2 col-md-3 col-lg-2 z-0'>
                        <div className="input-group h-100" onClick={(e) => {
                            productChange('Myco', e.target.checked)
                        }}>
                            <div className="input-group-text col-12 ProductTab">
                                <input className="form-check-input mt-0" type="checkbox" checked={mycoChecked}
                                       aria-label="Checkbox for following text input" is="inlineRadio1"/>
                                <div className="vr mx-2"></div>
                                <label className="form-check-label text-wrap flex-grow-1" for="inlineRadio1"
                                       style={{fontSize: "12px"}}>MycoDDR Trident</label>
                            </div>
                        </div>
                    </section>
                    <section className='px-1 py-2 col-md-3 col-lg-2 z-0'>
                        <div className="input-group h-100" onClick={(e) => {
                            productChange('Histo', e.target.checked)
                        }}>
                            <div className="input-group-text col-12 ProductTab">
                                <input className="form-check-input mt-0" type="checkbox" checked={histoChecked}
                                       aria-label="Checkbox for following text input" is="inlineRadio1"/>
                                <div className="vr mx-2"></div>
                                <label className="form-check-label text-wrap flex-grow-1" for="inlineRadio1"
                                       style={{fontSize: "12px"}}>Histo GM EIA</label>
                            </div>
                        </div>
                    </section>
                    <section className='px-1 py-2 col-md-3 col-lg-2 z-0'>
                        <div className="input-group h-100" onClick={(e) => {
                            productChange('Cocci EIA', e.target.checked)
                        }}>
                            <div className="input-group-text col-12 ProductTab">
                                <input className="form-check-input mt-0" type="checkbox" checked={cocciEIAChecked}
                                       aria-label="Checkbox for following text input" is="inlineRadio1"/>
                                <div className="vr mx-2"></div>
                                <label className="form-check-label text-wrap flex-grow-1" for="inlineRadio1"
                                       style={{fontSize: "12px"}}>Cocci Ab EIA</label>
                            </div>
                        </div>
                    </section>
                    <section className='px-1 py-2 col-md-3 col-lg-2 z-0'>
                        <div className="input-group h-100" onClick={(e) => {
                            productChange('Blasto', e.target.checked)
                        }}>
                            <div className="input-group-text col-12 ProductTab">
                                <input className="form-check-input mt-0" type="checkbox" checked={blastoChecked}
                                       aria-label="Checkbox for following text input" is="inlineRadio1"/>
                                <div className="vr mx-2"></div>
                                <label className="form-check-label text-wrap flex-grow-1" for="inlineRadio1"
                                       style={{fontSize: "12px"}}>Blasto Ab EIA</label>
                            </div>
                        </div>
                    </section>
                    <section className='px-1 py-2 col-md-3 col-lg-2 z-0'>
                        <div className="input-group h-100" onClick={(e) => {
                            if (!otherChecked) {
                                productChange('Other', e.target.checked)
                            }
                        }}
                        >
                            <div className="input-group-text col-12 ProductTab">
                                <input className="form-check-input mt-0" type="checkbox" checked={otherChecked}
                                       onClick={(e) => {
                                           if (otherChecked) {
                                               productChange('Other', e.target.checked)
                                           }
                                       }}
                                       aria-label="Checkbox for following text input"
                                       is="inlineRadio1"
                                />
                                <div className="vr mx-2"></div>

                                {otherChecked ?
                                    <textarea className="form-control" placeholder="Other..." id="floatingTextarea"
                                              value={otherProducts} rows={1} onChange={(e) => {
                                        setOtherProducts(e.target.value)
                                    }} style={{resize: "none"}}></textarea>
                                    : <><label className="form-check-label text-wrap flex-grow-1" for="inlineRadio1"
                                               style={{fontSize: "12px"}}>Other</label></>}
                            </div>
                        </div>
                    </section>
                </div>
                <div className="form-floating">
                    <textarea className="form-control mt-3" placeholder="Add additional notes" id="floatingTextarea"
                              value={formData.Notes__c} onChange={(e) => {
                        setFormData({...formData, Notes__c: e.target.value})
                    }}></textarea>
                    <label for="floatingTextarea">Notes</label>
                </div>
            </div>
            <ReCAPTCHA
                sitekey={process.env.REACT_APP_SITE_KEY}
                size="invisible"
                ref={reCaptchaRef}
            />
            <CustomerFooter onClick={() => {
                setSubmitted(1);
            }}/>
        </div>
    )
}

export default Home