import React, { useState, useEffect } from 'react'
import moment from 'moment';
import axios from 'axios';


function AdminHeader(props) {

  const [campaignOptions, setCampaignOptions] = useState();
  const [campaignCookie, setCampaignCookie] = useState();
  const [user, setUser] = useState();


  useEffect(() => {
    axios.get("/salesforce/campaigns")
    .then((response) => {
      //Checks for Campaign Cookie and assigns Campaign Drop down the cookie value
      const campaigns = response.data;
      const decodedCookie = decodeURIComponent(document.cookie);
      const ca = decodedCookie.split(";");
      let campaignTitle = null;
      
      if (decodedCookie.includes("campaign=")) {
        let cookie;
        for (let i = 0; i < ca.length; i++) {
          cookie = ca[i].trim();
  
          if (cookie.indexOf("campaign=") === 0) {
            cookie = cookie.substring("campaign=".length);
          }
        }
        for (const campaign of campaigns) {
          campaignTitle = campaign.columns.find((obj) => obj.value === cookie);
          
          if (campaignTitle) {
            campaignTitle = campaign.columns[0].value;
            props.campaignChanged(campaign.columns[6].value)
            break; // Exit the loop if an object is found
          }
        }
        
      }
      
      setCampaignOptions(response.data);
      setCampaignCookie(campaignTitle);
    });
  
  }, []);

  

  return (
      <div className='d-flex flex-column'>
        <div className='d-flex flex-column flex-md-row justify-content-between align-items-center p-2'>
          <a className='d-flex align-items-center navbar-brand verlag-bold mb-2 mb-md-0' href='/' style={{ color: '#002F87', fontSize: '2rem' }}>
            <img
              src='/images/immy_logo.svg'
              width='50'
              height='50'
              className='d-inline-block align-top'
              alt=''
              style={{ color: '#002F87' }}
            />
            IMMY
          </a>
          {campaignOptions && props.user ? 
            <select className="form-select headerSelect verlag-bold campaignDropdown" data onChange={(e) => {props.campaignChanged(e.target.value === 'Unassigned' ? null : e.target.value)}} id="floatingSelect" aria-label="Floating label select example">
              <option value={null}>Unassigned</option>
              {campaignOptions.map((campaign) => {
                if(campaign.columns[0].value === campaignCookie){
                  return <option selected value={campaign.columns[6].value}>{campaign.columns[0].value}</option>
                } else {
                  return <option value={campaign.columns[6].value}>{campaign.columns[0].value}</option>
                }
              })}
            </select>
            : 
              <></>
            }
        </div>
        <hr className='m-1'/>
      </div>
  )
}

export default AdminHeader