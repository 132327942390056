import logo from './logo.svg';
import './App.css';
import Home from './components/Home';
import Signin from './components/Signin';
import { BrowserRouter as Router, Routes, Route, Link, redirect } from 'react-router-dom';
import Admin from './components/Admin';
import PasswordReset from './components/PasswordReset';


function App() {
  return (
    <div className="App bg-secondary d-flex justify-content-center h-100">
      {/* <Home/> */}
      <Router>
        <Routes>
          <Route exact path='/login' element={<Signin/>}></Route>
          <Route exact path='/' element={<Home/>}></Route>
          <Route exact path='/admin' element={<Admin/>}></Route>
          <Route exact path='/reset' element={<PasswordReset/>}></Route>
        </Routes>
      </Router>
      {/* <Signin/> */}
    </div>
  );
}

export default App;
